<template>
  <component :is="questionData === undefined ? 'div' : 'b-card'">

    <div v-if="questionData">

      <!-- Form: Personal Info Form -->
      <b-form class="mt-1" @submit.prevent="onSubmit">
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon
              icon="UserIcon"
              size="19"
          />
          <h4 class="mb-0 ml-50">
            Вопрос
          </h4>
        </div>

        <b-row>

          <!-- Field: Вопрос -->
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <b-form-group
                label="Вопрос"
                label-for="question"
            >
              <b-form-textarea
                  id="question"
                  rows="3"
                  max-rows="6"
                  v-model="questionData.question"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Файл вопроса -->
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <b-img v-if="questionData.question_file"
                thumbnail
                fluid
                   width="280"
                   height="280"
                :src="questionUrl"
                alt="Файл вопроса"
            />

            <b-form-group
                label="Файл вопроса"
                label-for="question-file"
            >
              <b-button
                  :variant="questionData.question_file ? 'primary' : 'success'"
                  @click="$refs.refInputQuestionEl.click()"
              >
                <input
                    ref="refInputQuestionEl"
                    type="file"
                    class="d-none"
                    id="question-file"
                    @input="inputImageRenderer"
                />
                <span v-if="questionData.question_file" class="d-none d-sm-inline">Update</span>
                <span v-else class="d-none d-sm-inline">Add</span>
                <feather-icon
                    icon="EditIcon"
                    class="d-inline d-sm-none"
                />
              </b-button>
            </b-form-group>
          </b-col>

        </b-row>

        <!-- Header: Personal Info -->
        <div class="d-flex mt-2">
          <feather-icon
              icon="MapPinIcon"
              size="19"
          />
          <h4 class="mb-0 ml-50">
            Ответ
          </h4>
        </div>

        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">

          <!-- Field: Address Line 1 -->
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <b-form-group
                label="Ответ"
                label-for="answer"
            >
              <b-form-textarea
                  rows="3"
                  max-rows="6"
                  id="answer"
                  v-model="questionData.answer"
              />
            </b-form-group>
          </b-col>


          <!-- Field: Файл ответа -->
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <b-img v-if="questionData.answer_file"
                   thumbnail
                   fluid
                   width="180"
                   height="180"
                   :src="answerUrl"
                   alt="Файл ответа"
            />

            <b-form-group
                label="Файл ответа"
                label-for="answer-file"
            >
              <b-button
                  :variant="questionData.answer_file ? 'primary' : 'success'"
                  @click="$refs.refInputAnswerEl.click()"
              >
                <input
                    ref="refInputAnswerEl"
                    type="file"
                    class="d-none"
                    id="answer-file"
                    @input="inputAnswerImageRenderer"
                />
                <span v-if="questionData.answer_file" class="d-none d-sm-inline">Update</span>
                <span v-else class="d-none d-sm-inline">Add</span>
                <feather-icon
                    icon="EditIcon"
                    class="d-inline d-sm-none"
                />
              </b-button>
            </b-form-group>
          </b-col>

          <!-- Field: Answer Comment -->
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <b-form-group
                label="Комментарий к ответу"
                label-for="answer-comment"
            >
              <b-form-textarea
                  rows="3"
                  max-rows="6"
                  id="answer-comment"
                  v-model="questionData.answer_comment"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Comment -->
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <b-form-group
                label="Комментарий"
                label-for="comment"
            >
              <b-form-input
                  id="comment"
                  v-model="questionData.comment"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Theme -->
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <b-form-group
                label="Тема"
                label-for="theme"
            >
              <v-select
                  v-model="questionData.theme_id"
                  :options="themesOptions"
                  :clearable="false"
                  input-id="theme"
                  :reduce="theme => theme.id"
                  label="name"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Связи -->
          <b-col
              cols="12"
              md="6"
              lg="4"
          >
            <b-form-group
                label="Связи"
                label-for="links"
            >
              <v-select
                  v-model="questionData.movies"
                  :options="moviesOptions"
                  :clearable="true"
                  input-id="links"

                  label="title_ru"
                  multiple
                  :filterable="false"
                  @search="fetchMovies"
              >
                <template slot="no-options">
                  Начните печатать название фильма
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    <b-avatar
                        size="20"
                        :src="option.poster_preview"
                        variant="primary"
                    />
                    {{ option.title_ru }} ({{ option.year }}) [{{ option.votes_kp }}]
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    <b-avatar
                        size="20"
                        :src="option.poster_preview"
                        variant="primary"
                    />
                    {{ option.title_ru }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-button
                variant="primary"
                type="submit"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :disabled="isSaving"
            >
              <span v-if="isSaving">
                <b-spinner small />
                Сохраняем...
              </span>
              <span v-else>
                Сохранить
              </span>
            </b-button>


            <b-button
                variant="outline-secondary"
            >
              Сбросить
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BFormTextarea, BImg, BAvatar,
  BSpinner,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import questionStoreModule from './questionStoreModule'
import moviesStoreModule from '../database/movieStoreModule'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import vSelect from "vue-select";
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BFormTextarea,
    BImg,
    BAvatar,
    vSelect,
    BSpinner,
  },
  data() {
    return {
    }
  },
  setup(props) {
    const questionData = ref(null)
    const questionUrl = ref(null)
    const answerUrl = ref(null)
    const isSaving = ref(false)

    const QUESTIONS_APP_STORE_MODULE_NAME = 'app-questions'
    const MOVIES_STORE_MODULE_NAME = 'movies'

    // Register module
    if (!store.hasModule(QUESTIONS_APP_STORE_MODULE_NAME)) store.registerModule(QUESTIONS_APP_STORE_MODULE_NAME, questionStoreModule)
    if (!store.hasModule(MOVIES_STORE_MODULE_NAME)) store.registerModule(MOVIES_STORE_MODULE_NAME, moviesStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUESTIONS_APP_STORE_MODULE_NAME)) store.unregisterModule(QUESTIONS_APP_STORE_MODULE_NAME)
      if (store.hasModule(MOVIES_STORE_MODULE_NAME)) store.unregisterModule(MOVIES_STORE_MODULE_NAME)
    })

    const themesOptions = ref([])
    const moviesOptions = ref([])

    store.dispatch('app-questions/fetchQuestion', { id: router.currentRoute.params.id })
      .then(response => {
        questionData.value = response.data.data
        questionUrl.value = response.data.data.question_url
        answerUrl.value = response.data.data.answer_url
        themesOptions.value = response.data.meta.themes
      })
      .catch(error => {
        /*if (error.response.status === 404) {
          questionData.value = undefined
        }*/
      })

    const onSubmit = () => {
      isSaving.value = true
      store.dispatch('app-questions/saveQuestion', { id: router.currentRoute.params.id, question: questionData.value })
      .then(response => {
        questionData.value = response.data.data
        questionUrl.value = response.data.data.question_url
        answerUrl.value = response.data.data.answer_url
        isSaving.value = false
      })
    }

    const fetchMovies = (search, loading) => {
      if(search.length >= 3) {
        loading(true)
        console.log(moviesOptions)
        store.dispatch('movies/fetchMovies', {
          q: search,
          per_page: 40,
        })
        .then(response => {
          moviesOptions.value = response.data.data
          loading(false)
        })
      }

    }

    const refInputQuestionEl = ref(null)
    const refInputAnswerEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputQuestionEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      const file = refInputQuestionEl.value.files[0]
      store.dispatch('app-questions/upload', file)
          .then(response => {
            questionData.value.question_file = response.data.path
            questionUrl.value = response.data.url
          })
    })
    const { inputImageRenderer: inputAnswerImageRenderer } = useInputImageRenderer(refInputAnswerEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      //questionData.value.answer_img = base64
      const file = refInputAnswerEl.value.files[0]
      store.dispatch('app-questions/upload', file)
          .then(response => {
            questionData.value.answer_file = response.data.path
            answerUrl.value = response.data.url
          })
    })

    return {
      questionData,
      questionUrl,
      answerUrl,
      themesOptions,
      moviesOptions,
      fetchMovies,
      isSaving,
      onSubmit,

      refInputQuestionEl,
      refInputAnswerEl,
      inputImageRenderer,
      inputAnswerImageRenderer,

      avatarText,
    }
  },
}
</script>

<style>

</style>
